<template>
  <router-view
    @save="$emit('save')"
    @confirm="$emit('confirm')"
    @delete="$emit('delete')"
    @close="$emit('close')"
    v-model="passport"
    :scenario="scenario"
  />
</template>

<script>
export default {
  name: 'PassportDashboard',
  props: {
    value: Object,
    scenario: String
  },
  computed: {
    passport: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
